import React from 'react'
import Index from '../container/Change-Password/index';

const ChangePassword = () => {
  return (
    <div>
        <Index/>
    </div>
  )
}

export default ChangePassword;